import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import { navigate } from "@reach/router";
import Layout from "../../components/Layout";
import Content, { HTMLContent } from "../../components/Content";

// share
import SharePartsOfTroublePagePreview from "./../share-parts-of-trouble-page";
import SharePlacesPageTemplate from "./../share-places-page";
import SharePickupNewsPageTemplate from "./../share-pickup-news-page";
import ShareHistoryPageTemplate from "./../share-history-page";
import ShareInfomationPageTemplate from "./../share-infomation-page";
import { ChloasmaRankingPageTemplate } from "../chloasma/chloasma-ranking-page";
import { FrecklesRankingPageTemplate } from "../freckles/freckles-ranking-page";
import { SlackAndLiftUpRankingPageTemplate } from "../slack-and-lift-up/slack-and-lift-up-ranking-page";
import { GapPoreRankingPageTemplate } from "../gap-pore/gap-pore-ranking-page";
import { WrinkleRankingPageTemplate } from "../wrinkle/wrinkle-ranking-page";

// data
import ePlusSPData from "./../../data/machines/e_plus_sp.json";

const onPressToMoveScreenWithQuery = hash => {
  navigate(`/beauty-medical-treatment/price${hash}`);
};

export const EPlusSRPageTemplate = ({ title, content, contentComponent }) => {
  return (
    <>
      <div className="c-bread">
        <div className="c-bread__inner">
          <div className="c-bread__item">
            <Link className="c-bread__link" to={"/"}>
              <div className="c-bread__text">トップ</div>
            </Link>
          </div>
          <div className="c-bread__item">
            <div className="c-bread__text">
              {ePlusSPData[0] && ePlusSPData[0].title.length
                ? ePlusSPData[0].title
                : null}
            </div>
          </div>
        </div>
      </div>

      <section className="l-section-lv2 l-main-media-hero">
        <div className="l-main-media-hero__inner">
          <div className="c-simple-title -small">
            <h2 className="c-simple-title__text">
              {ePlusSPData[0] && ePlusSPData[0].title.length
                ? ePlusSPData[0].title
                : null}
            </h2>
          </div>

          {/* {
            ePlusSPData[0] && ePlusSPData[0].duration.length ? (
              ePlusSPData[0].duration.map((item, index) => {
                return (
                  <>
                    <section>
                      <div className="c-border-simple-title">
                        <h3 className="c-border-simple-title__text">{item.title}</h3>
                      </div>

                      <div className="c-tile2">
                        <div className="c-tile2__inner">
                          <div className="c-tile2__head">
                            {
                              item && item.time_table[index].table_title[index].text.length ? (
                                item.time_table[index].table_title.map((tabelItem) => {
                                  return (
                                    <div className="c-tile2__head__item">
                                      <p className="c-tile2__head__text">{tabelItem.text}</p>
                                    </div>
                                  )
                                })
                              ):null
                            }
                          </div>
                          <div className="c-tile2__body">
                            <div className="c-tile2__body__row">
                              {
                                item && item.time_table[index].table_contents[index].text.length ? (
                                  item.time_table[index].table_contents.map((contentsItem) => {
                                    return (
                                      <div className="c-tile2__body__item">
                                        <p className="c-tile2__body__text">{contentsItem.text}</p>
                                      </div>
                                    )
                                  })
                                ):null
                              }
                              </div>
                          </div>
                        </div>
                      </div>

                      {
                        item && item.caution.length ? (
                          item.caution.map((item) => {
                            return (
                              <div className="c-text u-pc-mt20 u-sp-mt10">{item.text}</div>
                            )
                          })
                        ) : null
                      }
                    </section>
                  </>
                )
              })
            ) : null
          } */}

          {/* {
            ePlusSPData[0] && ePlusSPData[0].expect_actions.length ? (
              ePlusSPData[0].expect_actions.map((item) => {
                return (
                  <>
                    <section className="u-pc-mt100 u-sp-mt60">
                      <div className="c-border-simple-title">
                        <h3 className="c-border-simple-title__text">{item.title}</h3>
                      </div>

                      <div className="c-tile3">
                        <div className="c-tile3__inner">
                          <div className="c-tile3__body">
                            <div className="c-tile3__body__row">
                              {
                                item.lables.map((item) => {
                                  return (
                                    <div className="c-tile3__body__item">
                                      <p className="c-tile3__body__text">{item.text}</p>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </>
                )
              })
            ) : null
          } */}

          {ePlusSPData[0] && ePlusSPData[0].operating_description.length
            ? ePlusSPData[0].operating_description.map(item => {
                return (
                  <>
                    <section className="u-pc-mt100 u-sp-mt60">
                      <div className="c-border-simple-title">
                        <h3 className="c-border-simple-title__text">
                          {item.title}
                        </h3>
                      </div>

                      <ul className="c-text-list">
                        {item.contents.map(item => {
                          return (
                            <li className="c-text-list__item">{item.text}</li>
                          );
                        })}
                      </ul>
                    </section>
                  </>
                );
              })
            : null}

          {ePlusSPData[0] && ePlusSPData[0].mode.length
            ? ePlusSPData[0].mode.map((item, index) => {
                return (
                  <>
                    <section className="u-pc-mt100 u-sp-mt60">
                      <div className="c-border-simple-title">
                        <h3 className="c-border-simple-title__text">
                          {item.title}
                        </h3>
                      </div>

                      <section>
                        <div className="c-border-bottom-simple-title">
                          <h4 className="c-border-bottom-simple-title__text">
                            {item.time_table_title}
                          </h4>
                        </div>

                        <div className="c-tile2">
                          <div className="c-tile2__inner">
                            <div className="c-tile2__head">
                              {item && item.time_table[0].table_title.length
                                ? item.time_table[0].table_title.map(
                                    tabelItem => {
                                      {
                                        tabelItem.text === "" ? null : (
                                          <div className="c-tile2__head__item">
                                            <p className="c-tile2__head__text">
                                              {tabelItem.text}
                                            </p>
                                          </div>
                                        );
                                      }
                                    }
                                  )
                                : null}
                            </div>
                            <div className="c-tile2__body">
                              <div className="c-tile2__body__row">
                                {item &&
                                item.time_table[0].table_contents.length
                                  ? item.time_table[0].table_contents.map(
                                      contentsItem => {
                                        return (
                                          <div className="c-tile2__body__item">
                                            <p className="c-tile2__body__text">
                                              {contentsItem.text}
                                            </p>
                                          </div>
                                        );
                                      }
                                    )
                                  : null}
                              </div>
                            </div>
                          </div>
                        </div>

                        {item && item.caution.length
                          ? item.caution.map(item => {
                              return (
                                <div className="c-text u-pc-mt20 u-sp-mt10">
                                  {item.text}
                                </div>
                              );
                            })
                          : null}
                      </section>

                      {item && item.expect_actions.length
                        ? item.expect_actions.map(item => {
                            return (
                              <>
                                <section className="u-pc-mt70 u-sp-mt40">
                                  <div className="c-border-bottom-simple-title">
                                    <h4 className="c-border-bottom-simple-title__text">
                                      {item.title}
                                    </h4>
                                  </div>

                                  <div className="c-tile3 u-mt00">
                                    <div className="c-tile3__inner">
                                      <div className="c-tile3__body">
                                        <div className="c-tile3__body__row">
                                          {item.lables.map(item => {
                                            return (
                                              <div className="c-tile3__body__item">
                                                <p className="c-tile3__body__text">
                                                  {item.text}
                                                </p>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </>
                            );
                          })
                        : null}

                      {item && item.description.length
                        ? item.description.map(item => {
                            return (
                              <>
                                <section className="u-pc-mt70 u-sp-mt40">
                                  <div className="c-border-bottom-simple-title">
                                    <h4 className="c-border-bottom-simple-title__text">
                                      {item.title}
                                    </h4>
                                  </div>

                                  <ul className="c-text-list">
                                    {item.contents.map(item => {
                                      return (
                                        <li className="c-text-list__item">
                                          {item.text}
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </section>
                              </>
                            );
                          })
                        : null}
                    </section>
                  </>
                );
              })
            : null}

          {ePlusSPData[0] && ePlusSPData[0].treatment_interval.length
            ? ePlusSPData[0].treatment_interval.map(item => {
                return (
                  <>
                    <section className="u-pc-mt100 u-sp-mt60">
                      <div className="c-border-simple-title">
                        <h3 className="c-border-simple-title__text">
                          {item.title}
                        </h3>
                      </div>

                      <ul className="c-text-list">
                        {item.contents.map(item => {
                          return (
                            <li className="c-text-list__item">{item.text}</li>
                          );
                        })}
                      </ul>
                    </section>
                  </>
                );
              })
            : null}

          {ePlusSPData[0] && ePlusSPData[0].flow_treatment.length
            ? ePlusSPData[0].flow_treatment.map(item => {
                return (
                  <>
                    <section className="u-pc-mt100 u-sp-mt60">
                      <div className="c-border-simple-title">
                        <h3 className="c-border-simple-title__text">
                          {item.title}
                        </h3>
                      </div>

                      <div className="c-flow-treatment">
                        <div className="c-flow-treatment__inner">
                          {item.contents.map((item, index) => {
                            return (
                              <div className="c-flow-treatment__item">
                                <div className="c-flow-treatment__title">
                                  {item.title}
                                </div>
                                <p className="c-flow-treatment__description">
                                  {item.description}
                                </p>
                                <div className="c-flow-treatment__circle"></div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </section>
                  </>
                );
              })
            : null}

          {/* {
            ePlusSPData[0] && ePlusSPData[0].episode_care.length ? (
              ePlusSPData[0].episode_care.map((item) => {
                return (
                  <>
                    <section className="u-pc-mt100 u-sp-mt60">
                      <div className="c-border-simple-title">
                        <h3 className="c-border-simple-title__text">{item.title}</h3>
                      </div>

                      <ul className="c-text-list">
                        {
                          item.contents.map((item) => {
                            return (
                              <li className="c-text-list__item">
                                {item.text}
                              </li>
                            )
                          })
                        }
                      </ul>
                    </section>
                  </>
                )
              })
            ) : null
          } */}

          {ePlusSPData[0] && ePlusSPData[0].treatment_pain.length
            ? ePlusSPData[0].treatment_pain.map(item => {
                return (
                  <>
                    <section className="u-pc-mt100 u-sp-mt60">
                      <div className="c-border-simple-title">
                        <h3 className="c-border-simple-title__text">
                          {item.title}
                        </h3>
                      </div>

                      <ul className="c-text-list">
                        {item.contents.map(item => {
                          return (
                            <li className="c-text-list__item">{item.text}</li>
                          );
                        })}
                      </ul>
                    </section>
                  </>
                );
              })
            : null}

          {ePlusSPData[0] && ePlusSPData[0].downtime.length
            ? ePlusSPData[0].downtime.map(item => {
                return (
                  <>
                    <section className="u-pc-mt100 u-sp-mt60">
                      <div className="c-border-simple-title">
                        <h3 className="c-border-simple-title__text">
                          {item.title}
                        </h3>
                      </div>

                      <ul className="c-text-list">
                        {item.contents.map(item => {
                          return (
                            <li className="c-text-list__item">{item.text}</li>
                          );
                        })}
                      </ul>
                    </section>
                  </>
                );
              })
            : null}

          {ePlusSPData[0] && ePlusSPData[0].aftercare.length
            ? ePlusSPData[0].aftercare.map(item => {
                return (
                  <>
                    <section className="u-pc-mt100 u-sp-mt60">
                      <div className="c-border-simple-title">
                        <h3 className="c-border-simple-title__text">
                          {item.title}
                        </h3>
                      </div>

                      <ul className="c-text-list">
                        {item.contents.map(item => {
                          return (
                            <li className="c-text-list__item">{item.text}</li>
                          );
                        })}
                      </ul>
                    </section>
                  </>
                );
              })
            : null}

          {ePlusSPData[0] && ePlusSPData[0].precaution.length
            ? ePlusSPData[0].precaution.map(item => {
                return (
                  <>
                    <section className="u-pc-mt100 u-sp-mt60">
                      <div className="c-border-simple-title">
                        <h3 className="c-border-simple-title__text">
                          {item.title}
                        </h3>
                      </div>

                      <ul className="c-text-list">
                        {item.contents.map(item => {
                          return (
                            <li className="c-text-list__item">{item.text}</li>
                          );
                        })}
                      </ul>
                    </section>
                  </>
                );
              })
            : null}

          <div className="u-txt-c u-pc-mt100 u-sp-mt60">
            <div
              className="c-border-button -arrow"
              onClick={() => onPressToMoveScreenWithQuery("#female-e-plus-sr")}
            >
              <p className="c-border-button__text">料金を確認</p>
            </div>
          </div>

          {typeof window !== "undefined" &&
            window.location.hash === "#prev-chloasma-screen" && (
              <>
                <div className="c-simple-title -small u-pc-mt100 u-sp-mt50">
                  <h2 className="c-simple-title__text">
                    大きなしみ(老人性色素斑等)施術ランキング
                  </h2>
                </div>

                <ChloasmaRankingPageTemplate />
              </>
            )}

          {typeof window !== "undefined" &&
            window.location.hash === "#prev-freckles-screen" && (
              <>
                <div className="c-simple-title -small u-pc-mt100 u-sp-mt50">
                  <h2 className="c-simple-title__text">
                    小さなしみ(雀卵斑等)施術ランキング
                  </h2>
                </div>

                <FrecklesRankingPageTemplate />
              </>
            )}

          {typeof window !== "undefined" &&
            window.location.hash === "#prev-slack-and-lift-up-screen" && (
              <>
                <div className="c-simple-title -small u-pc-mt100 u-sp-mt50">
                  <h2 className="c-simple-title__text">
                    たるみ・リフトアップ施術ランキング
                  </h2>
                </div>

                <SlackAndLiftUpRankingPageTemplate />
              </>
            )}

          {typeof window !== "undefined" &&
            window.location.hash === "#prev-gap-pore-screen" && (
              <>
                <div className="c-simple-title -small u-pc-mt100 u-sp-mt50">
                  <h2 className="c-simple-title__text">
                    毛穴の開き施術ランキング
                  </h2>
                </div>

                <GapPoreRankingPageTemplate />
              </>
            )}

          {typeof window !== "undefined" &&
            window.location.hash === "#prev-wrinkle-screen" && (
              <>
                <div className="c-simple-title -small u-pc-mt100 u-sp-mt50">
                  <h2 className="c-simple-title__text">しわ施術ランキング</h2>
                </div>

                <WrinkleRankingPageTemplate />
              </>
            )}
        </div>
      </section>
    </>
  );
};

EPlusSRPageTemplate.propTypes = {
  title: PropTypes.string
};

const EPlusSRPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <EPlusSRPageTemplate title={post.frontmatter.title} />
      <SharePartsOfTroublePagePreview />
      <SharePlacesPageTemplate />
      <SharePickupNewsPageTemplate />
      <ShareHistoryPageTemplate />
      <ShareInfomationPageTemplate />
    </Layout>
  );
};

EPlusSRPage.propTypes = {
  data: PropTypes.object.isRequired,
  title: PropTypes.string
};

export default EPlusSRPage;

export const ePlusSRPageQuery = graphql`
  query EPlusSRPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
