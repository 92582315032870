import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import { navigate } from "@reach/router";
import { v4 } from "uuid";
import Layout from "../../components/Layout";
import Content, { HTMLContent } from "../../components/Content";

// share
import SharePartsOfTroublePagePreview from "./../share-parts-of-trouble-page";
import SharePlacesPageTemplate from "./../share-places-page";
import SharePickupNewsPageTemplate from "./../share-pickup-news-page";
import ShareHistoryPageTemplate from "./../share-history-page";
import ShareInfomationPageTemplate from "./../share-infomation-page";

// data
import gapPoreData from "./../../data/rankings/gap_pore.json";

const screenName = "gap-pore";

const onPressToMoveScreenWithQuery = link => {
  navigate(`/machines/${link}#prev-${screenName}-screen`);
};

export const GapPoreRankingPageTemplate = ({
  title,
  content,
  contentComponent
}) => {
  return (
    <>
      {gapPoreData[0].contents.map(item => {
        return (
          <section className="l-ranking-hero" key={v4()}>
            <div className={`c-ranking-title -${item.rank_name}`}>
              <h3 className="c-ranking-title__text">{item.rank_title}</h3>
            </div>
            <p className="u-text u-border-t-gray">{item.description}</p>
            {item.label === "" ? null : (
              <div className="u-txt-c u-pc-mt60 u-sp-mt30">
                <div
                  className="c-border-button -arrow"
                  onClick={() => onPressToMoveScreenWithQuery(item.label)}
                >
                  <p className="c-border-button__text">詳細を確認</p>
                </div>
              </div>
            )}
          </section>
        );
      })}

      <section className="l-section-lv2 l-main-media-hero">
        <div className="l-main-media-hero__inner">
          <div className="c-simple-title">
            <h2 className="c-simple-title__text">その他</h2>
          </div>

          {gapPoreData[0].others.map(item => {
            return (
              <section className="l-ranking-hero" key={v4()}>
                <div className={`c-ranking-title -${item.rank_name}`}>
                  <h3 className="c-ranking-title__text">{item.rank_title}</h3>
                </div>
                <p className="u-text">{item.description}</p>
                <div className="u-txt-c u-pc-mt60 u-sp-mt30">
                  <div
                    className="c-border-button -arrow"
                    onClick={() => onPressToMoveScreenWithQuery(item.label)}
                  >
                    <p className="c-border-button__text">詳細を確認</p>
                  </div>
                </div>
              </section>
            );
          })}
        </div>
      </section>
    </>
  );
};

GapPoreRankingPageTemplate.propTypes = {
  title: PropTypes.string
};

const GapPoreRankingPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <div className="c-bread">
        <div className="c-bread__inner">
          <div className="c-bread__item">
            <Link className="c-bread__link" to={"/"}>
              <div className="c-bread__text">トップ</div>
            </Link>
          </div>
          <div className="c-bread__item">
            <div className="c-bread__text">
              {gapPoreData[0].title}施術ランキング
            </div>
          </div>
        </div>
      </div>

      <section className="l-section-lv2 l-main-media-hero">
        <div className="l-main-media-hero__inner">
          <div className="c-simple-title">
            <h2 className="c-simple-title__text">
              {gapPoreData[0].title}施術ランキング
            </h2>
          </div>
          <GapPoreRankingPageTemplate title={post.frontmatter.title} />
        </div>
      </section>

      <SharePartsOfTroublePagePreview />
      <SharePlacesPageTemplate />
      <SharePickupNewsPageTemplate />
      <ShareHistoryPageTemplate />
      <ShareInfomationPageTemplate />
    </Layout>
  );
};

GapPoreRankingPage.propTypes = {
  data: PropTypes.object.isRequired,
  title: PropTypes.string
};

export default GapPoreRankingPage;

export const gapPoreRankingPageQuery = graphql`
  query GapPoreRankingPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
